import { ReactElement, useMemo } from 'react';
import { AvatarGroup, Box, Typography } from '@mui/material';
import { MEETING_AUDIT_STATUS_LABELS, MEETING_STATUS_LABELS, PAGE_URL } from '../../../constants';
import { AuditMeeting, LeaderList, MeetingAuditLog } from '../../../store';
import MeetingListItem from '../MeetingListItem';
import CabAvatar from '@CabComponents/CabAvatar';
import UpdatedDashboardWidget from '../UpdatedDashboardWidget';
import { DateTime } from 'luxon';
import colors from '../../../colors';


interface Props {
  auditLogs: MeetingAuditLog[];
  meetingsLoaded: boolean;
  leaders: LeaderList;
  allLoaded: boolean
}

interface MeetingProps {
  meeting: AuditMeeting;
  updatedDate: string;
  leaders: LeaderList;
  auditLogs: MeetingAuditLog[];
}

export const RecentlyChangedWidget = ({
  auditLogs, meetingsLoaded, leaders, allLoaded
}: Props): ReactElement => {
  const meetings = auditLogs.map((log) => log.meeting);
  const meetingKeys = useMemo(() => {
    return Object.keys(meetings);
  }, [meetings]);

  return (
    <UpdatedDashboardWidget
      title={'Recent Updates'}
      isLoading={!meetingsLoaded}
      isEmpty={meetingKeys.length === 0 && meetingsLoaded}
      emptyMessage={'You have no recently changed meetings'}
      allLoaded={allLoaded}
    >
      <Box>
        {
          meetingKeys && leaders && (
            meetingKeys.map((key) => 
              <Meeting 
                key={key} 
                meeting={meetings[Number(key)]}
                updatedDate={auditLogs[Number(key)].create_date}
                leaders={leaders}
                auditLogs={auditLogs}
              />)
          )
        }
      </Box>
    </UpdatedDashboardWidget>
  );
};


const Meeting = ({ meeting, updatedDate, leaders, auditLogs }: MeetingProps): ReactElement => {
  if (meeting?.leaders) { // shouldn't really be necessary but we've encountered errors when navigating quickly
    const leaderList = meeting ? leaders.leaders.filter(leader => meeting.leaders.includes(leader.id)) : [];
    const changedDate = DateTime.fromISO(updatedDate).toFormat('MM/dd/yyyy');
    const statusOnly = [2, 4, 5];
    const auditMeeting = auditLogs.find(curAuditMeeting => curAuditMeeting.meeting.id === meeting.id);

    return (
      <MeetingListItem
        meeting={meeting}
        isAudit
        key={meeting.id}
        navigateTo={meeting.is_poll ? `${PAGE_URL.POLL_RESULTS}/${meeting.id}/` : PAGE_URL.MEETINGS}
        titleSx={{fontWeight: 500, marginBottom: '4px'}}
        containerSx={{paddingLeft: 0, marginBottom: '1px'}}
        subtitle={
          <Box display='flex' alignItems='center' gap={1}>
            <AvatarGroup max={5} sx={{
              '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 14 },
            }}>
              {leaderList.map((leader) => {
                return (
                  <CabAvatar key={leader.id} name={`${leader.first_name} ${leader.last_name}`} 
                    src={leader.pic_url} color={leader.color} size='small' />
                );
              })}
            </AvatarGroup>
            <Typography variant='body2' color={colors.black700}>
              {`${changedDate}: `}
              {statusOnly.includes(meeting.status) ? '' : 'Updated to '}
              {auditMeeting?.from_status === 1 ? (
                <b>{MEETING_STATUS_LABELS[Number(meeting.status)]}</b>
              ) : (
                <b>{MEETING_AUDIT_STATUS_LABELS[Number(meeting.status)]}</b>
              )}
            </Typography>
          </Box>
        }
      />
    );
  } else {
    return <></>;
  }
};

  
export default RecentlyChangedWidget;