import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLE } from '../../../constants';
import { RootState } from '../../../store';
import RecentlyChangedWidget from './RecentlyChangedWidget';


const RecentlyChangedWidgetContainer = ({allLoaded}: {allLoaded: boolean}): ReactElement => {

  const leaders = useSelector((state: RootState) => state.leaders);
  const meetingAuditLogs = useSelector((state: RootState) => state.schedule.meetingAuditLogs);
  const loaded = useSelector((state: RootState) => state.schedule.meetingAuditLogsLoaded);
  const user = useSelector((state: RootState) => state.auth.user);

  const meetings = Object.values(meetingAuditLogs);
  
  const filteredMeetings = user?.active_license.user_role === USER_ROLE.INDIVIDUAL ? 
    meetings.filter(auditMeeting => (auditMeeting.meeting.leaders.includes(user.profile.user_leader) ||
    Number(auditMeeting.meeting.create_user) === user.id ))
    : meetings;

  return (
    <RecentlyChangedWidget
      auditLogs={filteredMeetings}
      meetingsLoaded={loaded}
      leaders={leaders}
      allLoaded={allLoaded}
    />
  );
};

export default RecentlyChangedWidgetContainer;