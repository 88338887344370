import { ReactElement } from 'react';
import CabinetPage from '../../components/Common/CabinetPage';
import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import InProgressWidget from './InProgressWidget';
import colors from '../../colors';
import { DateTime } from 'luxon';
import CreateMeetingButton from '../../components/Schedule/CreateMeetingButton/CreateMeetingButton';
import theme from '../../theme';
import RecentlyChangedWidget from './RecentlyChangedWidget';
import MeetingPollWidget from './MeetingPollWidget';
import { isLargeFormatDevice } from '../../utils/screenSizeUtils';


const HeaderBox = styled(Box, {label: "HeaderBox"})({
  backgroundColor: colors.black900,
  flexDirection: 'column',
  width: '100%',
  height: '180px',
  display: 'flex',
});

const DashboardBox = styled(Box, {label: "DashboardBox"})({
  height: '90%',
  marginTop: '-80px',
  marginLeft: '48px',
  marginRight: '48px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '20px',
  }
});

const TitleBox = styled(Box, {label: "TitleBox"})({
  marginLeft: '48px',
  marginRight: '48px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '20px',
    marginRight: '20px',
  },
});


export const Dashboard = (
  {meetingAuditLogsLoaded, meetingsLoaded}: {meetingAuditLogsLoaded: boolean, meetingsLoaded: boolean}
): ReactElement => {
  const user = useSelector((state: RootState) => state.auth.user);
  const now =  DateTime.local().toJSDate();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
 

  const allLoaded = meetingAuditLogsLoaded && meetingsLoaded;

  return (
    <CabinetPage
      pageName={'Dashboard'}
      headerBackgroundColor={isSm ? colors.white900 : colors.black900}
      noBorder={isSm ? false : true}
      headerContent={ isSm && user && isLargeFormatDevice() ? 
        <Box display='flex' width='100%' justifyContent='flex-end'>
          <CreateMeetingButton
            buttonTitle='Create Event'
          /> 
        </Box>
        : <></>
      }>
      <Box height='100%' overflow={{sm: 'auto', md: 'hidden'}}>
        { !isSm ? (
          <HeaderBox>
            <TitleBox>
              <Box display='flex' width='100%' justifyContent='space-between'>
                <Typography variant='h5' color={colors.white900} display='flex' alignItems='flex-end'>
                  {now.toLocaleString('US', { weekday: 'long', day: 'numeric', month: 'long' })}
                </Typography>
                { isLargeFormatDevice() && user && (
                  <CreateMeetingButton
                    buttonTitle='Create Event'
                    buttonSx={{backgroundColor: colors.darkThemeGreenPrimary, 
                      '&:hover':{backgroundColor: colors.greenLight}}}
                  />
                )}
              </Box>
              <Typography variant='h1' lineHeight={{sm: '54px', lg:'58px'}} noWrap color={colors.white900} 
                fontSize={{sm: '45px', lg: '50px'}}>
                Welcome, {user?.first_name}
              </Typography>
            </TitleBox>
          </HeaderBox>
        ) : (
          <>
          </>
        )}
        <DashboardBox>
          <Grid item xs={12} sm={12} height='100%'>
            <Grid container spacing={4} height='100%'>
              <Grid paddingBottom={{ sm: 0, md:4 }} item xs={12} sm={12} md={7} height='100%'>
                <InProgressWidget
                  allLoaded={allLoaded}
                />
              </Grid>
              <Grid item container xs={12} sm={12} md={5} spacing={4} height='100%'>
                <Grid item xs={12} height='50%'>
                  <MeetingPollWidget
                    allLoaded={allLoaded}
                  />
                </Grid>
                <Grid item xs={12} height='50%'>
                  <RecentlyChangedWidget
                    allLoaded={allLoaded}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DashboardBox>
      </Box>
    </CabinetPage>
  );
};
  
export default Dashboard;