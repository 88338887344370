import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { PAGE_URL } from '../../../constants';
import { Meeting } from '../../../store';
import { DateTime } from 'luxon';
import MeetingListItem from '../MeetingListItem';
import UpdatedDashboardWidget from '../UpdatedDashboardWidget';
import colors from '../../../colors';
import { useNavigate } from 'react-router';

interface Props {
  polls: Meeting[];
  meetingsLoaded: boolean;
  onUpdateStatus: (id: number, status: number) => Promise<void>;
  onCreateMeetingPoll: () => void;
  locked?: boolean;
  allLoaded: boolean
}

interface PollProps {
  poll: Meeting;
  onUpdateStatus: (id: number, status: number) => Promise<void>;
}

export const MeetingPollWidget = ({
  polls, meetingsLoaded, onUpdateStatus, onCreateMeetingPoll, locked, allLoaded
}: Props): ReactElement => {

  const [sortedPolls, setSortedPolls] = useState<Meeting[]>([]);

  const navigate = useNavigate();

  const handleOnClick = useCallback(() => navigate(PAGE_URL.POLL_RESULTS), [navigate]);

  useEffect(() => {
    const sorted =  polls.sort((a, b) => 
      DateTime.fromISO(b.create_date).valueOf() - DateTime.fromISO(a.create_date).valueOf());
    setSortedPolls(sorted);
  }, [polls]);

  return (
    <UpdatedDashboardWidget
      title={'Pending Polls'}
      buttonName={'View Meeting Polls'}
      isLoading={!meetingsLoaded}
      buttonOnClick={handleOnClick}
      isEmpty={sortedPolls.length === 0 && meetingsLoaded}
      accessBlocked={locked}
      upgradeMessage={'Upgrade your subscription plan to unlock Meeting Polls'}
      emptyMessage={'You have no pending meeting polls'}
      allLoaded={allLoaded}
    >
      <Box>
        {polls && (
          sortedPolls.map(poll => <Poll key={poll.id} poll={poll} onUpdateStatus={onUpdateStatus} />)
        )}
      </Box>
    </UpdatedDashboardWidget>
  );
};


const Poll = ({ poll, onUpdateStatus }: PollProps): ReactElement => {
  const respondents = Object.values(poll.participants || {}).filter(p => p.first_response_date).length;
  let percentRes = 0;
  if (poll.num_expected_participants !== undefined) {
    percentRes = (respondents / poll.num_expected_participants) * 100;
  }
  return (
    <MeetingListItem
      meeting={poll}
      onUpdateStatus={onUpdateStatus}
      navigateTo={`${PAGE_URL.POLL_RESULTS}/${poll.id}/`}
      titleSx={{fontWeight: 500, marginBottom: '4px'}}
      containerSx={{paddingLeft: 0, marginBottom: '1px'}}
      subtitle={
        <Box>
          <Typography variant='body2' fontSize='12px' 
            color={percentRes < 33 ? colors.redError : percentRes > 65 ? colors.greenPrimary : colors.coralPrimary}>
            {`${respondents}/${poll.num_expected_participants} participants responded`}
          </Typography>
        </Box>
      }
    />
  );
};

  
export default MeetingPollWidget;