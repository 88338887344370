import { DateTime } from 'luxon';
import { ReactElement, useState } from 'react';
import { EVENT_TYPE, MEETING_STATUS_LABELS, USER_ROLE } from '../../../constants';
import { 
  actions, Meetings, MeetingSlot, MeetingStatus, MeetingUpdate, RootState, ThunkDispatchType 
} from '../../../store';
import { trackEventWithExtra } from '../../../utils/appAnalyticsUtils';
import InProgessWidget from './InProgressWidget';
import { useDispatch, useSelector } from 'react-redux';


const InProgressWidgetContainer = ({allLoaded}: {allLoaded: boolean}): ReactElement => {
  const leaders = useSelector((state: RootState) =>  state.leaders);
  const meetingsLoaded = useSelector((state: RootState) =>  state.schedule.meetingsLoaded);
  const meetings = useSelector((state: RootState) =>  state.schedule.meetings);
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<ThunkDispatchType>();

  const updateMeeting = (
    meeting: MeetingUpdate, timeSlots: MeetingSlot[], files?: File[] | undefined
  ) => dispatch(actions.schedule.updateMeeting(meeting, timeSlots, files));

  // This state is only to fix a bug with ionic's onChange handler for selects
  //   The bug is that the select triggers onChange events too many times and for
  //   undetermined reasons (e.g. state changes that don't even effect the select)
  const [statusChangePending, setStatusChangePending] = useState<{[meetingId: number]: number}>({});

  const handleUpdateStatus = async (id: number, status: number) => {
    const date_scheduled = status !== MeetingStatus.PENDING ? DateTime.utc().toString() : null;
    // Required to counter an Ionic bug. See comment above where statusChangePending is defined
    if (statusChangePending[id] !== status) {
      setStatusChangePending(prev => ({...prev, [id]: status}));
      await updateMeeting({ id, date_scheduled, status }, []);
      setStatusChangePending(prev => ({...prev, [id]: -1}));
      trackEventWithExtra({
        eventName: EVENT_TYPE.SCHEDULING_CHANGE_STATUS, 
        extra: { status: MEETING_STATUS_LABELS[status] },
      });
    }
  };

  const nonPollMeetings: Meetings = {};
  Object.values(meetings).forEach(meeting => {
    if (!meeting.is_poll) {
      nonPollMeetings[meeting.id] = meeting;
    }
  });

  const filteredMeetings: Meetings = {};
  Object.values(nonPollMeetings).forEach(meeting => {
    if (meeting.leaders.includes(user?.profile.user_leader || -1) ||
    meeting.create_user?.id === user?.id) {
      filteredMeetings[meeting.id] = meeting;
    }
  });

  return (
    <InProgessWidget
      meetings={user?.active_license.user_role === USER_ROLE.INDIVIDUAL ? 
        filteredMeetings : nonPollMeetings}
      meetingsLoaded={meetingsLoaded}
      leaders={leaders}
      onUpdateStatus={handleUpdateStatus}
      allLoaded={allLoaded}
    />
  );
};

export default InProgressWidgetContainer;