import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, MeetingFilter, MeetingStatus, RootState, ThunkDispatchType } from '../../store';
import Dashboard from './Dashboard';


const DashboardContainer = (): ReactElement => {
  const meetingsLoaded = useSelector((root: RootState) => root.schedule.meetingsLoaded);
  const meetingAuditLogsLoaded = useSelector((root: RootState) => root.schedule.meetingAuditLogsLoaded);

  const dispatch = useDispatch<ThunkDispatchType>();

  const startPollingMeetings = useCallback((query: Partial<MeetingFilter>) => 
    dispatch(actions.schedule.startPollingMeetings(query)), [dispatch]);
  const startPollingAuditLogs = useCallback((limit: number) => 
    dispatch(actions.schedule.startPollingAuditLogs(limit)), [dispatch]);

  useEffect(() => {
    let stopPollMtgs = () => { /* */ };
    let stopPollAudit = () => { /* */ };
    (async () => {
      stopPollAudit = await startPollingAuditLogs(10);
      stopPollMtgs = await startPollingMeetings({ status: MeetingStatus.PENDING });
    })();
    return () => {
      stopPollMtgs();
      stopPollAudit();
    };
  }, [startPollingAuditLogs, startPollingMeetings]);

  return (
    <Dashboard
      meetingAuditLogsLoaded={meetingAuditLogsLoaded}
      meetingsLoaded={meetingsLoaded}
    />
  );
};

export default DashboardContainer;
