import { ReactElement, useCallback, useMemo } from 'react';
import { AvatarGroup, Box, Typography } from '@mui/material';
import { PAGE_URL } from '../../../constants';
import { LeaderList, Meeting, Meetings } from '../../../store';
import { sortMeetingLogs } from '../../../utils/scheduleUtils';
import MeetingListItem from '../MeetingListItem';
import CabAvatar from '@CabComponents/CabAvatar';
import UpdatedDashboardWidget from '../UpdatedDashboardWidget';

import { DateTime } from 'luxon';
import colors from '../../../colors';
import { useNavigate } from 'react-router';


interface Props {
  meetings: Meetings;
  meetingsLoaded: boolean;
  leaders: LeaderList;
  onUpdateStatus: (id: number, status: number) => Promise<void>;
  allLoaded: boolean
}

interface MeetingProps {
  meeting: Meeting;
  onUpdateStatus: (id: number, status: number) => Promise<void>;
  leaders: LeaderList;
}

export const InProgessWidget = ({
  meetings, meetingsLoaded, leaders, onUpdateStatus, allLoaded
}: Props): ReactElement => {
  const navigate = useNavigate();

  const sortedMeetings = useMemo(() => {
    return Object.keys(meetings).sort(sortMeetingLogs(meetings));
  }, [meetings]);

  const handleOnClick = useCallback(() => navigate(PAGE_URL.MEETINGS), [navigate]);

  return (
    <UpdatedDashboardWidget
      title={'Pending Meetings'}
      isLoading={!meetingsLoaded}
      buttonName='View Meeting Log'
      buttonOnClick={handleOnClick}
      isEmpty={sortedMeetings.length === 0 && meetingsLoaded}
      emptyMessage={'You have no pending Meetings or Polls'}
      allLoaded={allLoaded}
    >
      <Box>
        {sortedMeetings && leaders && (
          sortedMeetings.map((meetingId) => 
            <MeetingWidgetItem
              key={meetingId}
              meeting={meetings[meetingId]}
              onUpdateStatus={onUpdateStatus}
              leaders={leaders}
            />
          )
        )}
      </Box>
    </UpdatedDashboardWidget>
  );
};

const MeetingWidgetItem = ({ meeting, onUpdateStatus, leaders }: MeetingProps): ReactElement => {
  if (meeting?.leaders) { // shouldn't really be necessary but we've encountered errors when navigating quickly
    const leaderList = meeting ? leaders.leaders.filter(leader => meeting.leaders.includes(leader.id)) : [];
    const createDate = DateTime.fromISO(meeting.create_date).toFormat('MM/dd/yyyy');

    return (
      <MeetingListItem
        meeting={meeting}
        onUpdateStatus={onUpdateStatus}
        navigateTo={`${PAGE_URL.SCHEDULE}/${meeting.id}/`}
        titleSx={{fontWeight: 500, marginBottom: '4px'}}
        containerSx={{paddingLeft: 0, marginBottom: '1px'}}
        subtitle={
          <Box display='flex' alignItems='center' gap={1}>
            <AvatarGroup max={5} sx={{
              '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 14 },
            }}>
              {leaderList.map((leader) => {
                return (
                  <CabAvatar key={leader.id} name={`${leader.first_name} ${leader.last_name}`} 
                    src={leader.pic_url} color={leader.color} size='small' />
                );
              })}
            </AvatarGroup>
            <Typography variant='body2' color={colors.black700}>{'Initiated: ' + createDate}</Typography>
          </Box>
        }
      />
    );
  } else {
    return <></>;
  }
};

  
export default InProgessWidget;