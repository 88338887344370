import { DateTime } from 'luxon';
import { ReactElement, useMemo, useState } from 'react';
import { EVENT_TYPE, MEETING_STATUS_LABELS, PAGE_URL, USER_ROLE } from '../../../constants';
import { actions, MeetingSlot, MeetingStatus, MeetingUpdate, RootState, ThunkDispatchType } from '../../../store';
import { trackEventWithExtra } from '../../../utils/appAnalyticsUtils';
import { selectRealOrPromoMeetings } from '../../../store/schedule/selectors';
import MeetingPollWidget from './MeetingPollWidget';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const MeetingPollWidgetContainer = ({allLoaded}: {allLoaded: boolean}): ReactElement => {

  const meetings = useSelector((state: RootState) => selectRealOrPromoMeetings(state));
  const meetingsLoaded = useSelector((state: RootState) => state.schedule.meetingsLoaded);
  const features = useSelector((state: RootState) => state.auth.user?.features);
  const user = useSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch<ThunkDispatchType>();

  const updateMeeting = (
    meeting: MeetingUpdate, timeSlots: MeetingSlot[], files?: File[] | undefined
  ) => dispatch(actions.schedule.updateMeeting(meeting, timeSlots, files));

  // This state is only to fix a bug with ionic's onChange handler for selects
  //   The bug is that the select triggers onChange events too many times and for
  //   undetermined reasons (e.g. state changes that don't even effect the select)
  const [statusChangePending, setStatusChangePending] = useState<{[meetingId: number]: number}>({});

  // This is necessary to trigger "loaded" for promo data if it's being used
  const loaded = meetingsLoaded || Object.keys(meetings).length > 0;
  const navigate = useNavigate();
  const handleUpdateStatus = async (id: number, status: number) => {
    const date_scheduled = status !== MeetingStatus.PENDING ? DateTime.utc().toString() : null;
    // Required to counter an Ionic bug. See comment above where statusChangePending is defined
    if (statusChangePending[id] !== status) {
      setStatusChangePending(prev => ({...prev, [id]: status}));
      await updateMeeting({ id, date_scheduled, status }, []);
      setStatusChangePending(prev => ({...prev, [id]: -1}));
      trackEventWithExtra({
        eventName: EVENT_TYPE.SCHEDULING_CHANGE_STATUS, 
        extra: { status: MEETING_STATUS_LABELS[status] },
      });
    }
  };

  const handleCreateMeetingPoll = (): void => {
    navigate(PAGE_URL.SCHEDULE, {state: { createMeetingPoll: true}});
  };

  const polls = useMemo(() => (
    Object.values(meetings).filter(meeting => meeting.is_poll)
  ), [meetings]);

  const individualPolls = polls.filter(poll => (poll.leaders.includes(user?.profile.user_leader || -1) ||
  poll.create_user?.id === user?.id));

  return (
    <MeetingPollWidget
      polls={user?.active_license.user_role === USER_ROLE.INDIVIDUAL ? 
        individualPolls : polls}
      meetingsLoaded={loaded}
      onUpdateStatus={handleUpdateStatus}
      onCreateMeetingPoll={handleCreateMeetingPoll}
      locked={!features?.MEETING_POLLS}
      allLoaded={allLoaded}
    />
  );
};

export default MeetingPollWidgetContainer;
